import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/components/Home'
import Help from '@/components/Help'
import HelpAndroid from '@/components/HelpAndroid'
import Contact from '@/components/Contact'
import PrivacyPolicy from '@/components/PrivacyPolicy'
import Terms from '@/components/Terms'
import Questionnaire from '@/components/Questionnaire'
import SignupConfirm from '@/components/SignupConfirm'
import Introduction from '@/components/Introduction'
import Napkin from '@/components/Napkin'
import FailureNapkin from '@/components/FailureNapkin'

Vue.use(VueRouter)

const routes = [
  {name: 'Home', path: '/', component: Home},
  {name: 'Help', path: '/help', component: Help},
  {name: 'HelpAndroid', path: '/help/android', component: HelpAndroid},
  {name: 'Contact', path: '/contact', component: Contact},
  {name: 'PrivacyPolicy', path: '/privacy_policy', component: PrivacyPolicy},
  {name: 'Terms', path: '/terms', component: Terms},
  {name: 'Introduction', path: '/introduction', component: Introduction},
  {name: 'Questionnaire', path: '/questionnaire', component: Questionnaire},
  {name: 'SignupConfirm', path: '/signup_confirm', component: SignupConfirm},
  {name: 'Napkin', path: '/napkin', component: Napkin},
  {name: 'FailureNapkin', path: '/failure-napkin-20220830', component: FailureNapkin}
]

// eslint-disable-next-line no-new
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
