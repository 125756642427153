<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h3 class="napkin-title">障害の現況報告について</h3>

        <div>
          【発生日】<br/>
          2022年8月5日〜<br/>
          <br/>
          【障害内容】<br/>
          一部の施設において断続的なエラーの発生によりナプキンの排出困難な現象が発生。<br/>
          <br/>
          【現況について】<br/>
          パーツ改良し交換を行うことでナプキンの排出困難な状況を改善できることが判明。<br/>
          <br/>
          【復旧作業について】<br/>
          9月24日（土）より順次、復旧作業を行わせていただく予定でございます。<br/>
          <br/>
          一日でも早くみなさまがご利用できるように、引き続き復旧に全力を尽くし対応して参ります。<br/>
          今しばらくの間、ご迷惑をおかけ致しますことを深くお詫び申し上げます。<br/>
          何卒よろしくお願い申し上げます。<br/>
          <br/>
          <br/>
          オイテル株式会社<br/>
          <a href="https://www.oitr.jp/">https://www.oitr.jp/</a><br/>
        </div>

        <div style="margin-top: 100px"></div>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import "../style";

.napkin-table {
  border: 1px solid #DDD;
  margin-bottom: 30px;
}

.label {
  width: 110px;
}

.napkin-title {
  margin: 10px 10px;
}

</style>
