<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <br/>
        <h4>OiTrアプリをダウンロードいただき<br/>ありがとうございます。</h4>
        <br/>
        <p>
          Free pad dispenser OiTr(オイテル)は、ショッピングモール・オフィス・学校・公共施設などの個室トイレに、生理用ナプキンを常備し無料で提供するサービスです。
        </p>

        <br/>

        <h5>生理用ナプキン使用上の注意</h5>
        <ul>
          <li>お肌に合わない時はご使用を中止し、医師に相談してください。</li>
          <li>使用後のナプキンは個別ラップに包んで捨ててください。</li>
          <li>使用後のナプキンはトイレに流さないでください。</li>
        </ul>
        <p></p>

        <h5>生理用ナプキン情報</h5>
        <ul>
          <li>提供されている生理用ナプキンに関しては<router-link :to="{name: 'Napkin'}" target="_blank">生理用ナプキン情報ページ</router-link>をご覧ください。</li>
        </ul>
        <p></p>

        <h5>利用規約</h5>
        <ul>
          <li>
            <router-link :to="{name: 'Terms'}" target="_blank">利用規約</router-link>は、OiTrのサービスをご利用いただく際の取り扱いにつき、オイテル株式会社が定めるものです。OiTrのサービスの利用前に、利用規約に同意した上で本サービスをご利用ください。
          </li>
        </ul>
        <p></p>

        <h5>プライバシーポリシー</h5>
        <ul>
          <li>
            <router-link :to="{name: 'PrivacyPolicy'}" target="_blank">プライバシーポリシー</router-link>に従って個人情報等を取り扱いします。本サービスの利用前に、オイテル株式会社が定めるプライバシーポリシーに同意した上で本サービスをご利用ください。
          </li>
        </ul>
        <p></p>

        <br/>
        <hr/>
        <br/>

        <p>
          2021年7月31日 施行
        </p>

        <br/>
        <br/>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import "../style";

</style>
