<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h5 class="napkin-title">ロリエ スリムガード (特に多い昼用) 25cm 羽つき</h5>
        <v-simple-table class="napkin-table">
          <template v-slot:default>
            <tbody>
            <tr>
              <td class="label">医薬部外品</td>
              <td>生理用ナプキン</td>
            </tr>
            <tr>
              <td class="label">販売名</td>
              <td>ロリエADーd</td>
            </tr>
            <tr>
              <td class="label">構成材料</td>
              <td>表面材:ポリエチレン・ポリプロピレン・ポリエステル 色調：白</td>
            </tr>
            <tr>
              <td class="label">使用方法</td>
              <td>生理時に適宜取り替えてご使用ください。</td>
            </tr>
            <tr>
              <td class="label">使用上の注意</td>
              <td>
                お肌に合わない時は医師に相談してください。<br/>
                使用後のナプキンは個別ラップに包んですててください。<br/>
                トイレに流さないでください。<br/>
                使用後のナプキンは専用箱にすててください。
              </td>
            </tr>
            <tr>
              <td class="label">包装容器の材質</td>
              <td>プラ・PE</td>
            </tr>
            <tr>
              <td class="label">原産地</td>
              <td>MADE IN JAPAN</td>
            </tr>
            <tr>
              <td class="label">製造販売業者</td>
              <td>
                花王株式会社<br/>
                〒103-8210 東京都中央区日本橋茅場町1-14-10
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>

        <h5 class="napkin-title">エリス コンパクトガード (多い昼用) 羽つき 23cm</h5>
        <v-simple-table class="napkin-table">
          <template v-slot:default>
            <tbody>
              <tr>
                <td class="label">医薬部外品</td>
                <td>生理用ナプキン</td>
              </tr>
              <tr>
                <td class="label">販売名</td>
                <td>エリスCG-SRW3</td>
              </tr>
              <tr>
                <td class="label">構成材料</td>
                <td>表面材:ポリエステル・ポリエチレン 色調:白</td>
              </tr>
              <tr>
                <td class="label">製造販売元</td>
                <td>エリエールプロダクト株式会社<br/>〒329-1411 栃木県さくら市鷲宿4776-4</td>
              </tr>
              <tr>
                <td class="label">発売元</td>
                <td>大王製紙株式会社<br/>〒102-0071 東京都千代田区富士見2-10-2</td>
              </tr>
              <tr>
                <td class="label">使用上の注意</td>
                <td>●お肌に合わないときは医師に相談してください。●使用後のナプキンは、個別ラップに包んで捨ててください。●使用後、トイレに流さないでください。</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <br/>

        <p>OiTrで提供された生理用ナプキンについてのお問い合わせは下記の「お問い合わせフォーム」よりお願いします。</p>

        <v-btn color="primary" href="/contact">
          お問い合わせ
        </v-btn>

        <div style="margin-top: 100px"></div>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import "../style";

.napkin-table {
  border: 1px solid #DDD;
  margin-bottom: 30px;
}

.label {
  width: 110px;
}

.napkin-title {
  margin: 10px 10px;
}

</style>
