import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light:   {
        primary: '#68C3C5',
        secondary: '#418982',
        accent: '#f44336',
        error: '#e91e63',
        warning: '#ffc107',
        info: '#03a9f4',
        success: '#4caf50'
      }
    }
  }
});
