<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <br />
        <h3>OiTr アプリに遷移中…</h3>
        <br />
        <p>
          アプリに遷移しない場合は、<a href="oitr://app/signup_confirm">こちらをタップ</a>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods: {
    launchApp() {
      var IOS_SCHEME = 'oitr://app/signup_confirm';
      var IOS_STORE = 'https://app.oitr.jp'; //'iOSのストアURLをここに入れるべし！';
      var ANDROID_SCHEME = 'oitr://app/signup_confirm';
      //var ANDROID_PACKAGE = 'jp.oitr.mobile';
      var PC_SITE = 'https://app.oitr.jp';  //'公式サイトのURLとかここに入れるべし！'

      var userAgent = navigator.userAgent.toLowerCase();
      // iPhone端末ならアプリを開くかApp Storeを開く。
      if (userAgent.search(/iphone|ipad|ipod/) > -1) {
        document.location.href = IOS_SCHEME;
        setTimeout(function() {
          location.href = IOS_STORE;
        }, 500);
      }
      // Android端末ならアプリを開くかGoogle Playを開く。
      else if (userAgent.search(/android/) > -1) {
        //document.location = 'intent://#Intent;scheme=' + ANDROID_SCHEME
        //        + ';package=' + ANDROID_PACKAGE + ';end';
        document.location = ANDROID_SCHEME;
      }
      // その他・不明・PCなどの場合はサイトを開く。
      else {
        document.location = PC_SITE;
      }
    },
  },

  created() {
    this.launchApp();
  }
}
</script>

<style lang="scss" scoped>
@import "../style";

</style>
