<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <br />
        <h4>FAQ取得中…</h4>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  created () {
    location.replace('https://oitr.jp/faq?no-header=1');
  }
}
</script>
