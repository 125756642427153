<template>
  <div>
    <div class="header-image-container">
      <v-img
          cover
          src="/images/header-new.png"
          width="100%"
          max-width="1000px"
          class="header grey darken-4"
      ></v-img>
    </div>
    <v-container>
      <v-col cols="12" class="text-center">
        <p>
          OiTrアプリは以下よりダウンロードしてください。<br/>
        </p>
        <div class="store-button-container">
          <img
              class="store-button"
              :src="require('/images/apple.png')"
              height="40"
              @click="onClickAppStore"
          />
          <img
              class="store-button"
              :src="require('/images/google.png')"
              height="40"
              @click="onClickGooglePlay"
          />
        </div>
      </v-col>
      <v-col cols="12" class="text-center">
        <h4 style="text-align: center; margin: -25px 0 20px 0">生理用ナプキンは無料です</h4>

        <p class="message">
          OiTrは、商業施設・オフィス・学校・公共施設などの個室トイレに、生理用ナプキンを常備し無料で提供するサービスです。<br/>
          <br/>
          OiTrで配る生理用ナプキンは、広告出稿企業の広告費から賄うことで無料にて提供しています。<br/>
        </p>
        <h4 style="width: 100%; text-align: center;">トイレットペーパーと同様にトイレに<br/>生理用品が常備される社会へ</h4>
        <p class="message">
          OiTrは、生理に伴うさまざまな負担を軽減するために「トイレットペーパーと同様にトイレに生理用品が常備される社会」を創出したいという思いから生まれました。
        </p>
      </v-col>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },

  methods: {
    onClickAppStore() {
      location.href = 'https://itunes.apple.com/us/app/oitr/id1547921594'
    },

    onClickGooglePlay() {
      location.href = 'https://play.google.com/store/apps/details?id=jp.oitr.mobile'
    }
  }
}
</script>

<style lang="scss" scoped>
.header-image-container {
  background-color: #222;

  .header {
    margin: 0 auto;
  }
}

.screenshot-container {
  .screenshot {
    width: 15%;
    max-width: 120px;
    margin: 3px;
  }
}

.store-button-container {
  .store-button {
    height: 40px;
    margin: 5px 5px 30px 5px;
    //cursor: pointer;
  }

  .not-available {
    opacity: 0.3;
  }
}

.message {
  margin: 10px auto 20px auto;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  max-width: 400px;
}
</style>
