<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div v-if="isShowAppBar">
          <br/>
          <h3>利用規約</h3>
          <br/>
        </div>

        <p>
          この規約は、お客様が、オイテル株式会社（以下「当社」）が提供する 「無料生理用ナプキン・ディスペンサー オイテル」サービス（以下「本サービス」）をご利用頂く際の取扱いにつき 定めるものです。本規約に同意した上で本サービスをご利用ください。
        </p>

        <h5>第１条（定義）本コンテンツ</h5>
        <p> 本規約上で使用する用語の定義は、次に掲げるとおりとします。

        <p>
          （1）本サービス<br/>
          当社が運営するサービス及び関連するサービス<br/>
          （2）本アプリ<br/>
          本サービスの利用するために必要なアプリ<br/>
          （3）利用者<br/>
          本サービスを利用する全ての方<br/>
          （4）登録利用者<br/>
          本サイトの利用者登録が完了した方<br/>
          （5）ＩＤ<br/>
          本サービスの利用のために登録利用者が固有に持つ文字列<br/>
          （6）パスワード<br/>
          ＩＤに対応して登録利用者が固有に設定する暗号<br/>
          （7）個人情報<br/>
          住所、氏名、職業、電話番号等個人を特定することのできる情報の総称<br/>
          （8）登録情報<br/>
          登録利用者が本サイトにて登録した情報の総称（投稿情報は除く）<br/>
          （9）知的財産<br/>
          発明、考案、植物の新品種、意匠、著作物その他の人間の創造的活動により生み出されるもの（発見または解明がされた自然の法則または現象であって、産業上の利用可能性があるものを含む）、商標、商号その他事業活動に用いられる商品または役務を表示するもの及び営業秘密その他の事業活動に有用な技術上または営業上の情報<br/>
          （10）知的財産権<br/>
          特許権、実用新案権、育成者権、意匠権、著作権、商標権その他の知的財産に関して法令により定められた権利または法律上保護される利益に係る権利
        </p>


        <h5>第２条（本規約への同意）</h5>
        <p>
          １ 利用者は、本利用規約に同意頂いた上で、本サービスを利用できるものとします。<br/>
          ２ 利用者が、本サービスをスマートフォンその他の情報端末にダウンロードし、本規約への同意手続を行った時点で、利用者と当社との間で、本規約の諸規定に従った利用契約が成立するものとします。<br/>
        </p>

        <h5>第３条（規約の変更）</h5>
        <p>
          １ 当社は、利用者の承諾を得ることなく、いつでも、本規約の内容を改定することができるものとし、利用者はこれを異議なく承諾するものとします。<br/>
          ２ 当社は、本規約を改定するときは、その内容について当社所定の方法により利用者に通知します。<br/>
          ３ 前本規約の改定の効力は、当社が前項により通知を行った時点から生じるものとします。<br/>
          ４ 利用者は、本規約変更後、本サービスを利用した時点で、変更後の本利用規約に異議なく同意したものとみなされます。
        </p>

        <h5>第４条（会員の入会手続）</h5>
        <p>
          １ 本サービスへ利用を希望する方（以下「登録希望者」）は、本規約に同意した上で、
          本アプリより所定の方法で入会の申込を行ってください。<br/>
          ２ 入会の申込をした方は、弊社がその申込を承諾し、ＩＤ登録が完了した時点から登録ユーザとなります。<br/>
          ３ 弊社は、登録ユーザー向けにメールで連絡事項の告知や必要に応じてその他の情報提供を行います。あらかじめご了承ください。<br/>
          ４ 弊社は、登録希望者が次の各号のいずれか一つに該当する場合は、弊社の判断により入会申込を承諾しないことがあります。<br/>
          一 登録希望者が、弊社の定める方法によらず入会の申込を行った場合<br/>
          二 登録希望者が、過去に本規約または弊社の定めるその他の利用規約等に違反したことを理由として退会処分を受けた者である場合<br/>
          三 登録希望者が、不正な手段をもって登録を行っていると弊社が判断した場合<br/>
          四 登録希望者が、本人以外の情報を登録している場合<br/>
          五 その他弊社が不適切と判断した場合
        </p>

        <h5>第５条（アカウントの管理）</h5>
        <p>
          １ 利用者は、利用に際して本アプリ上で登録した情報（以下、「登録情報」といいます。メールアドレスやID・パスワード等を含みます）について、自己の責任の下、任意に登録、管理するものとします。利用者は、これを第三者に利用させ、または貸与、譲渡、名義変更、売買などをしてはならないものとします。<br/>
          ２ 当社は、登録情報によって本サービスの利用があった場合、利用登録をおこなった本人が利用したものと扱うことができ、当該利用によって生じた結果ならびにそれに伴う一切の責任については、利用登録を行った本人に帰属するものとします。<br/>
          ３ 利用者は、登録情報の不正使用によって当社または第三者に損害が生じた場合、当社および第三者に対して、当該損害を賠償するものとします。<br/>
          ４ 登録情報の管理は、利用者が自己の責任の下で行うものとし、登録情報が不正確または虚偽であったために利用者が被った一切の不利益および損害に関して、当社は責任を負わないものとします。<br/>
          ５ 登録情報が盗用されまたは第三者に利用されていることが判明した場合、利用者は直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
        </p>

        <h5>第６条（個人情報等の取り扱い）</h5>
        <p>
          個人情報及び利用者情報については、当社が別途定める「プライバシーポリシー」に則り、適正に取り扱うこととします。
        </p>

        <h5>第７条（禁止行為）</h5>
        <p>
          本サービスの利用に際し、当社は、利用者に対し、次に掲げる行為を禁止します。当社において、利用者が禁止事項に違反したと認めた場合、利用者用の一時停止、退会処分その他当社が必要と判断した措置を取ることができます。
        </p>
        <p>
          （１）当社または第三者の知的財産権を侵害する行為<br/>
          （２）当社または第三者の名誉・信用を毀損または不当に差別もしくは誹謗中傷する行為<br/>
          （３）当社または第三者の財産を侵害する行為、または侵害する恐れのある行為<br/>
          （４）当社または第三者に経済的損害を与える行為<br/>
          （５）当社または第三者に対する脅迫的な行為<br/>
          （６）コンピューターウィルス、有害なプログラムを仕様またはそれを誘発する行為<br/>
          （７）本サービス用インフラ設備に対して過度な負担となるストレスをかける行為<br/>
          （８）当サイトのサーバーやシステム、セキュリティへの攻撃<br/>
          （９）当社提供のインターフェース以外の方法で当社サービスにアクセスを試みる行為<br/>
          （１０）一人の利用者が、複数の利用者IDを取得する行為<br/>
          （１１）上記の他、当社が不適切と判断する行為
        </p>

        <h5>第８条（免責）</h5>
        <p>
          １ 当社は、本サービスの内容変更、中断、終了によって生じたいかなる損害についても、一切責任を負いません。<br/>
          ２ 当社は、本サービスを使用した際に生じたトラブル（ナプキンがお肌に合わない等）については、一切責任を負いません。<br/>
          ３ 当社は、利用者の本サービスの利用環境について一切関与せず、また一切の責任を負いません。<br/>
          ４ 当社は、本サービスが利用者の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、利用者による本サービスの利用が利用者に適用のある法令または業界団体の内部規則等に適合すること、および不具合が生じないことについて、何ら保証するものではありません。<br/>
          ５ 当社は、本サービスが全ての情報端末に対応していることを保証するものではなく、本サービスの利用に供する情報端末のＯＳのバージョンアップ等に伴い、本サービスの動作に不具合が生じる可能性があることにつき、利用者はあらかじめ了承するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により、当該不具合が解消されることを保証するものではありません。<br/>
          ６ 利用者は、AppStore、GooglePlay等のサービスストアの利用規約および運用方針の変更等に伴い、本サービスの一部又は全部の利用が制限される可能性があることをあらかじめ了承するものとします。<br/>
          ７ 当社は、本サービスを利用したことにより直接的または間接的に利用者に発生した損害について、一切賠償責任を負いません。<br/>
          ８ 当社は、利用者その他の第三者に発生した機会逸失、業務の中断その他いかなる損害（間接損害や逸失利益を含みます）に対して、当社が係る損害の可能性を事前に通知されていたとしても、一切の責任を負いません。<br/>
          ９ 第１項乃至前項の規定は、当社に故意または重過失が存する場合又は契約書が消費者契約法上の消費者に該当する場合には適用しません。<br/>
          １０ 前項が適用される場合であっても、当社は、過失（重過失を除きます。）による行為によって利用者に生じた損害のうち、特別な事情から生じた損害については、一切賠償する責任を負わないものとします。<br/>
          １１ 利用者と他の利用者との間の紛争及びトラブルについて、当社は一切責任を負わないものとします。利用者と他の利用者でトラブルになった場合でも、両者同士の責任で解決するものとし、当社には一切の請求をしないものとします。<br/>
          １２ 利用者は、本サービスの利用に関連し、他の利用者に損害を与えた場合または第三者との間に紛争を生じた場合、自己の費用と責任において、かかる損害を賠償またはかかる紛争を解決するものとし、当社には一切の迷惑や損害を与えないものとします。<br/>
          １３ 利用者の行為により、第三者から当社が損害賠償等の請求をされた場合には、利用者の費用（弁護士費用）と責任で、これを解決するものとします。当社が、当該第三者に対して、損害賠償金を支払った場合には、利用者は、当社に対して当該損害賠償金を含む一切の費用（弁護士費用及び逸失利益を含む）を支払うものとします。<br/>
          １４ 利用者が本サービスの利用に関連して当社に損害を与えた場合、利用者の費用と責任において当社に対して損害を賠償（訴訟費用及び弁護士費用を含む）するものとします。
        </p>

        <h5>第９条（権利譲渡の禁止）</h5>
        <p>
          １ 利用者は、予め当社の書面による承諾がない限り、本規約上の地位および本規約に基づく権利または義務の全部または一部を第三者に譲渡してはならないものとします。<br/>
          ２ 当社は、本サービスの全部または一部を当社の裁量により第三者に譲渡することができ、その場合、譲渡された権利の範囲内で利用者のアカウントを含む、本サービスに係る利用者の一切の権利が譲渡先に移転するものとします。
        </p>

        <h5>第１０条（分離可能性）</h5>
        <p>
          本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
        </p>

        <h5>第１１条（当社への連絡方法）</h5>
        <p>
          本サービスに関する利用者の当社へのご連絡・お問い合わせは、本アプリまたは当社が運営するwebサイト内の適宜の場所に設置するお問い合わせフォームからの送信または当社が別途指定する方法により行うものとします。
        </p>

        <h5>第１２条（準拠法、管轄裁判所）</h5>
        <p>
          １ 本規約の有効性，解釈及び履行については，日本法に準拠し，日本法に従って解釈されるものとする。<br/>
          ２ 当社と利用者等との間での論議・訴訟その他一切の紛争については、訴額に応じて、東京簡易裁判所又は東京地方裁判所を専属的合意管轄裁判所とします｡
        </p>


        <br/>
        <hr/>
        <br/>

        <p>
          制定：2021年2月5日
        </p>

        <br/>
        <br/>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    isShowAppBar: true
  }),

  created() {
    // #no-app-bar がついていたらAppBarを非表示
    if (this.$route.hash.indexOf('no-app-bar') >= 0) {
      this.isShowAppBar = false
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../style";

</style>
