<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <br/>

        <h4>電話でのお問い合わせ</h4>
        <p>
          03-6432-7214（11:00〜17:00 平日のみ）
        </p>

        <h4>LINEでのお問い合わせ</h4>
        <p>
          AI応答チャット：24時間受付対応（現在は未対応）<br/>
          有人チャット：11:00～17:00（平日のみ）
        </p>
        <p>
          LINEでのお問い合わせは、下記より友だち追加をお願いします。
        </p>
        <a href="https://lin.ee/45wFR5LWJ"><img src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                                                alt="友だち追加" height="36" border="0"></a>

        <br/>
        <br/>
        <hr/>
        <br/>


        <h4>LINEチャットサポート利用規約</h4>

        <br/>

        <p>
          本利用規約は、オイテル株式会社（以下、「当社」といいます。）が運営するお問い合わせサービス
          （以下、「本サービス」といいます。）を利用するすべてのお客さま（以下、「利用者」といいます。）
          に適用されます。<br/>
          ご利用にあたっては、必ず本利用規約をお読みいただき、同意のうえご利用いただきますようお願いいたします。
          なお、当社は、利用者が本サービスを利用することをもって、
          本利用規約の内容についてご同意いただいたものとみなします。<br/><br/>
          「LINEチャットサポート」のご利用にあたって、お問い合わせ前に必ず下記同意事項をご確認ください。
        </p>

        <h5>第１条（本サービスについて）</h5>
        <p>
          1.本サービスは、LINE株式会社（以下、「LINE社」といいます。）が提供するコミュニケーショ
          ンアプリ「LINE」（以下、「LINE」といいます。）の機能を利用した文字による当社指定サー
          ビスに関する問い合わせに対して、LINEを通じてAI技術を利用したチャットボット、
          またはオペレーターが文字で回答するサービスです。<br/>
          2.前項の当社指定サービスとは、当社が提供する「Free Pad Dispenser OiTr」「OiTrアプリ」（以
          下、総称して「当社指定サービス」といいます。）をいいます。<br/>
          3.本サービスの利用にあたっては、お客様の通信機器にLINEのインストールが必要です。LINEの設
          定・利用は、お客様の負担および責任において準備し、本サービスの利用に適した環境に設定・維持するものとします。<br/>
          4.当社の許可なく、本サービスにおけるお客様と弊社とのチャット内容を
          SNS等の外部へ転載すること を禁止します。<br/>
        </p>

        <h5>第2条（本サービスの利用時間）</h5>
        <p>
          本サービスは、当社が別途定める受付時間に限りご利用いただけます。
        </p>

        <h5>第3条（お問い合せの範囲）</h5>
        <p>
          本サービスは、次の各号に掲げるお問い合わせについては、回答しないものとします。<br/>
          (1)当社指定サービスと関係のないお問い合わせ<br/>
          (2)本サービスのお問い合わせ範囲外のサービスに関するお問い合わせ<br/>
          (3)本サービスの利用履歴に関するお問い合わせ<br/>
          (4)電話または電子メール等により回答することが適切と判断するお問い合わせ<br/>
          (5)法令、諸規則または監督官庁等による規制、命令等を鑑み、当社が回答を控えると判断したお問い合わせ<br/>
          (6)前各号のほか、本サービスにより、
          利用者に対して適切な回答をすることができないと当社が判断したお問い合わせ
        </p>

        <h5>第4条（回答の中断）</h5>
        <p>
          1.当社は、以下のいずれかに該当する場合、利用者の同意なく、本サービスにおけるお問い合わせの回答
          を中断できるものとします。<br/>
          (1)文字化け等により入力内容が判読できない場合<br/>
          (2)当社からの回答後、一定時間を経過しても利用者からの返答がない場合<br/>
          (3)同一の利用者から、同一内容のお問い合わせが重複して到達した場合<br/>
          (4)当社または当社従業員等に対する誹謗中傷、嫌がらせ等、本サービス利用目的に合致しないと当社が判断した場合<br/>
          (5)お問い合わせに対する通常必要とされる回答を行ったと当社が判断した場合<br/>
          (6)問い合わせの回答に必要のない個人情報が記載されていた場合<br/>
          (7)本サービスに関係のない画像・動画・スタンプ等が送信された場合<br/>
          (8)電話、メール、その他の方法により対応することが適切であると当社が判断した場合<br/>
          (9)お問い合わせの履歴を残すことが不適当・不適切と当社が判断する場合<br/>
          (10)その他、当社が利用者との本サービスによる対応を継続することがふさわしくないと判断した場合
        </p>

        <h5>第5条（個人情報等）</h5>
        <p>
          1.本サービスで取り扱う情報については、当社が別に定める「プライバシーポリシー（OiTrアプリ記載）」
          に基づき取り扱うものとします。<br/>
          2.本サービスにおいては、お客様やその他の方の個人情報（氏名、住所、電話番号、メールアドレスなどの情報）
          を直接記載しないようお願いします。<br/>
        </p>

        <h5>第6条（サービスの変更等）</h5>
        <p>
          当社は、お客様への事前の通知や承諾なく、本サービスの終了、もしくは中止し、
          またはその内容を変更することができるものとします。
        </p>

        <br/>
        <hr/>
        <br/>

        <p>
          2021年2月5日 施行
        </p>

        <br/>
        <br/>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {
    //location.replace('https://docs.google.com/forms/d/e/1FAIpQLSfqU288KJUwYNe1BFN8fAWQK0oeAvCiw_qaOZ0vHuTIhkoCWw/viewform');
  }
}
</script>

<style lang="scss" scoped>
@import "../style";

</style>
