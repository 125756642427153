<template>
  <v-app>
<!--    <v-app-bar-->
<!--        app-->
<!--        color="primary"-->
<!--        dark-->
<!--        v-if="isShowAppBar"-->
<!--    >-->
<!--      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="hidden-md-and-up"></v-app-bar-nav-icon>-->

<!--      <div class="d-flex">-->
<!--        <router-link :to="{name: 'Home'}">-->
<!--          <Logo class="logo" viewBox="0 0 55 37"/>-->
<!--        </router-link>-->
<!--      </div>-->

<!--      <v-spacer></v-spacer>-->

<!--      <div class="hidden-sm-and-down">-->
<!--        <v-btn :to="{name: 'Home'}" exact text>ホーム</v-btn>-->
<!--        <v-btn :to="{name: 'Help'}" exact text>ヘルプ</v-btn>-->
<!--        <v-btn :to="{name: 'Terms'}" exact text>利用規約</v-btn>-->
<!--        <v-btn :to="{name: 'PrivacyPolicy'}" exact text>プライバシーポリシー</v-btn>-->
<!--        &lt;!&ndash;v-btn :to="{name: 'Contact'}" exact text>お問い合わせ</v-btn&ndash;&gt;-->
<!--      </div>-->
<!--    </v-app-bar>-->

<!--    <v-navigation-drawer-->
<!--        v-model="drawer"-->
<!--        fixed-->
<!--        temporary-->
<!--        v-if="isShowAppBar"-->
<!--    >-->
<!--      <v-list-->
<!--          nav-->
<!--          dense-->
<!--      >-->
<!--        <v-list-item-group-->
<!--            active-class="secondary&#45;&#45;text text&#45;&#45;accent-4 font-weight-bold"-->
<!--        >-->
<!--          <v-list-item :to="{name: 'Home'}" exact>-->
<!--            <v-list-item-icon>-->
<!--              <v-icon>mdi-home</v-icon>-->
<!--            </v-list-item-icon>-->
<!--            <v-list-item-content>-->
<!--              <v-list-item-title>ホーム</v-list-item-title>-->
<!--            </v-list-item-content>-->
<!--          </v-list-item>-->

<!--          <v-list-item :to="{name: 'Help'}" exact>-->
<!--            <v-list-item-icon>-->
<!--              <v-icon>mdi-help-circle</v-icon>-->
<!--            </v-list-item-icon>-->
<!--            <v-list-item-content>-->
<!--              <v-list-item-title>ヘルプ</v-list-item-title>-->
<!--            </v-list-item-content>-->
<!--          </v-list-item>-->

<!--          <v-list-item :to="{name: 'Terms'}" exact>-->
<!--            <v-list-item-icon>-->
<!--              <v-icon>mdi-text-box-check</v-icon>-->
<!--            </v-list-item-icon>-->
<!--            <v-list-item-content>-->
<!--              <v-list-item-title>利用規約</v-list-item-title>-->
<!--            </v-list-item-content>-->
<!--          </v-list-item>-->

<!--          <v-list-item :to="{name: 'PrivacyPolicy'}" exact>-->
<!--            <v-list-item-icon>-->
<!--              <v-icon>mdi-file-lock</v-icon>-->
<!--            </v-list-item-icon>-->
<!--            <v-list-item-content>-->
<!--              <v-list-item-title>プライバシーポリシー</v-list-item-title>-->
<!--            </v-list-item-content>-->
<!--          </v-list-item>-->

<!--          &lt;!&ndash;v-list-item :to="{name: 'Contact'}" exact>-->
<!--            <v-list-item-icon>-->
<!--              <v-icon>mdi-chat-processing-outline</v-icon>-->
<!--            </v-list-item-icon>-->
<!--            <v-list-item-content>-->
<!--              <v-list-item-title>お問い合わせ</v-list-item-title>-->
<!--            </v-list-item-content>-->
<!--          </v-list-item&ndash;&gt;-->

<!--        </v-list-item-group>-->
<!--      </v-list>-->
<!--    </v-navigation-drawer>-->

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-footer v-if="isShowAppBar" padless>
      <v-col
          class="text-center"
          cols="12"
          center
          style="font-size: 60%"
      >
        Copyright {{ new Date().getFullYear() }} <a href="https://oitr.jp" target="_blank">OiTr, Inc.</a> All rights reserved.
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
//import Logo from '@/assets/oitr-logo.svg'

export default {
  data: () => ({
    drawer: false,
    group: null,
    isShowAppBar: true
  }),

  watch: {
    group() {
      this.drawer = false
    }
  },

  created() {
    // #no-app-bar がついていたらAppBarを非表示
    if (this.$route.hash.indexOf('no-app-bar') >= 0) {
      this.isShowAppBar = false
    }
  },

  components: {
    //Logo
  }
}
</script>

<style lang="scss" scoped>
@import "style";

.logo {
  height: 40px;
  padding-top: 8px;
  margin-left: 10px;
}

</style>
