<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <br />
        <h4>ローディング中…</h4>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  created () {
    //location.replace('https://forms.gle/sPr2JzNS25x6VaFN7');
    // グランスタ東京
    // location.replace('https://docs.google.com/forms/d/e/1FAIpQLScw6Pwc4yx_KP0aDu8eW9WVbmuKTcx1XsCYuY2R31fI_FhYlw/viewform');
    // セントレア
    location.replace('https://forms.office.com/pages/responsepage.aspx?id=5oZTU6etqUahxaybT13SJSMWLVIN-qtDvWVJFYvzE3xUMVpFR1hGT1lMOVFYSE9CNEtBUThWVUJPSy4u');
    // 大塚製薬
    // location.replace('https://www.otsuka-plus1.com/shop/formlp/tocoelle_lp_brand.aspx?utm_source=to_brand&utm_medium=banner&utm_campaign=tocoelle_brand_supplement&waad=WFF6itMw&ugad=WFF6itMw')
  }
}
</script>

<style lang="scss" scoped>
@import "../style";

</style>
