<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div v-if="isShowAppBar">
          <br/>
          <h3>個人情報保護方針</h3>
          <br/>
        </div>

        <p>
          オイテル株式会社（以下、「当社」と言います。）では、お客様からお預かりする個人情報の重要性を強く認識し、個人情報の保護に関する法律、その他の関係法令を遵守すると共に、以下に定めるプライバシーポリシーに従って、個人情報を安全かつ適切に取り扱うことを宣言いたします。
        </p>

        <h5>個人情報の定義</h5>
        <p>
          本プライバシーポリシーにおいて、個人情報とは生存する個人に関する情報であって、氏名、生年月日、住所、電話番号、メールアドレス等、特定の個人を識別することができるものをいいます。
        </p>

        <h5>個人情報の管理</h5>
        <p>
          お客様からお預かりした個人情報は、不正アクセス、紛失、漏えい等が起こらないよう、慎重かつ適切に管理します。
        </p>

        <h5>個人情報の利用目的</h5>
        <p>
          当社では、お客様からのお問い合わせやオイテルのサービスへご利用等を通じて、お客様の氏名、生年月日、住所、電話番号、メールアドレス等の個人情報をご提供いただく場合があります。その場合は、以下に示す利用目的のために、適正に利用するものと致します。
        </p>
        <ul>
          <li>お客様からのお問い合わせ等に対応するため。</li>
          <li>お客様からお申し込みいただいたサービス等の提供のため。</li>
          <li>当社が実施するアンケートへのご協力のお願いのため</li>
          <li>当社のサービス向上・改善、新サービスを検討するための分析等を行うため。</li>
          <li>個人を識別できない形で統計データを作成し、当アプリおよびお客様の参考資料とするため。</li>
        </ul>
        <p>
        </p>

        <h5>個人情報の第三者提供</h5>
        <p>
          お客様からお預かりした個人情報を、個人情報保護法その他の法令に基づき開示が認められる場合を除き、ご本人様の同意を得ずに第三者に提供することはありません。
        </p>

        <h5>個人情報の開示・訂正・削除について</h5>
        <p>
          お客様からお預かりした個人情報の開示・訂正・削除をご希望の場合は、ご本人様よりお申し出ください。適切な本人確認を行った後、速やかに対応させていただきます
        </p>

        <br/>
        <hr/>
        <br/>

        <p>
          制定: 2021年2月5日<br/>
          最終改定: 2021年9月10日
        </p>

        <br/>
        <br/>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    isShowAppBar: true
  }),

  created() {
    // #no-app-bar がついていたらAppBarを非表示
    if (this.$route.hash.indexOf('no-app-bar') >= 0) {
      this.isShowAppBar = false
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../style";

</style>
